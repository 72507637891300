<template>
	<b-container>
		<b-row class="mt-3">
			<b-col>
				<h5>銷售報告</h5>
				<p>顯示指定期間的銷售報告</p>
			</b-col>
			<b-col>
				<b-form-select class="mt-3" v-model="shop" :options="shopOptions"></b-form-select>
			</b-col>
		</b-row>
		<b-button-group>
			<label class="pt-1 pr-2">日結</label>
			<b-form-checkbox v-model="isChecked" switch size="lg" @change="endDate = null" />
			<label class="pt-1">月結</label>
		</b-button-group>
		<b-form-group label="開始日期">
			<b-form-datepicker v-model="startDate" />
		</b-form-group>
		<b-form-group v-if="isChecked" label="結束日期">
			<b-form-datepicker v-model="endDate" />
		</b-form-group>

		<b-button-group>
			<label class="pt-1 pr-2">開啟時間</label>
			<b-form-checkbox v-model="isTimeChecked" switch size="lg" @change="endTime = null" />
			<label class="pt-1">關閉時間</label>
		</b-button-group>
		<b-row>
			<b-col cols="6">
				<b-form-group label="開始時間">
					<b-time v-model="startTime" />
				</b-form-group>
			</b-col>
			<b-col cols="6">
				<b-form-group v-if="isTimeChecked" label="結束時間">
					<b-time v-model="endTime" />
				</b-form-group>
			</b-col>
		</b-row>

		<b-row>
			<b-col v-if="isChecked" class="mt-3" cols="6" lg="4">
				<b-card-title>營業日數</b-card-title>
				<b-card-sub-title>{{ orderSummary.dateCount }}日</b-card-sub-title>
			</b-col>
			<b-col class="mt-3" cols="6" lg="4">
				<b-card-title>自取單數</b-card-title>
				<b-card-sub-title>{{ orderSummary.pickupCount }}單</b-card-sub-title>
			</b-col>
			<b-col class="mt-3" cols="6" lg="4">
				<b-card-title>自取小計</b-card-title>
				<b-card-sub-title>${{ Math.round(orderSummary.pickupTotal * 10) / 10 }}</b-card-sub-title>
			</b-col>
			<b-col class="mt-3" cols="6" lg="4">
				<b-card-title>外送單數</b-card-title>
				<b-card-sub-title>{{ orderSummary.deliveryCount }}單</b-card-sub-title>
			</b-col>
			<b-col class="mt-3" cols="6" lg="4">
				<b-card-title>外送小計</b-card-title>
				<b-card-sub-title>${{ Math.round(orderSummary.deliveryTotal * 10) / 10 }}</b-card-sub-title>
			</b-col>
			<b-col class="mt-3" cols="6" lg="4">
				<b-card-title>總單數</b-card-title>
				<b-card-sub-title>{{ orderSummary.count }}單</b-card-sub-title>
			</b-col>
			<b-col class="mt-3" cols="6" lg="4">
				<b-card-title>總計</b-card-title>
				<b-card-sub-title>${{ Math.round(orderSummary.total * 10) / 10 }}</b-card-sub-title>
			</b-col>
		</b-row>

		<b-table class="mt-3" :fields="fields" :items="orderSummary.days" hover responsive />
	</b-container>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
	data() {
		return {
			shop: null,
			isChecked: false,
			startDate: null,
			endDate: null,
			isTimeChecked: false,
			startTime: null,
			endTime: null,
			fields: [
				{
					key: 'date',
					label: '日期',
					sortable: true,
				},
				{
					key: 'pickupCount',
					label: '自取單數',
					formatter: (value) => {
						return `${value}單`
					},
					sortable: true,
					class: 'd-none d-sm-table-cell',
				},
				{
					key: 'pickupTotal',
					label: '自取小計',
					formatter: (value) => {
						return `$${Math.round(value * 10) / 10}`
					},
					sortable: true,
					class: 'd-none d-sm-table-cell',
				},
				{
					key: 'deliveryCount',
					label: '外送單數',
					formatter: (value) => {
						return `${value}單`
					},
					sortable: true,
					class: 'd-none d-sm-table-cell',
				},
				{
					key: 'deliveryTotal',
					label: '外送小計',
					formatter: (value) => {
						return `$${Math.round(value * 10) / 10}`
					},
					sortable: true,
					class: 'd-none d-sm-table-cell',
				},
				{
					key: 'count',
					label: '總單數',
					formatter: (value) => {
						return `${value}單`
					},
					sortable: true,
				},
				{
					key: 'total',
					label: '總計',
					formatter: (value) => {
						return `$${Math.round(value * 10) / 10}`
					},
					sortable: true,
				},
			],
		}
	},

	created() {
		this.$store.state.endDate = null
		// this.$store.dispatch('bindCollectionByMerchant', { col: 'orders', merchantId: localStorage.merchantId })
		this.$store.dispatch('bindCollectionByMerchant', { col: 'shops', merchantId: localStorage.merchantId })
	},

	watch: {
		async startDate(newStartDate) {
			this.$store.state.startDate = newStartDate
			await this.$store.dispatch('bindOrders')
		},
		async endDate(newEndDate) {
			this.$store.state.endDate = newEndDate
			await this.$store.dispatch('bindOrders')
		},
	},

	computed: {
		...mapState({
			orders: (state) => state.orders,
			shops: (state) => state.shops,
		}),

		shopOptions() {
			if (!this.shops) return []
			let ret = this.shops.map((shop) => {
				return {
					text: shop.name,
					value: {
						id: shop.id,
						name: shop.name,
					},
				}
			})
			ret.unshift({ text: '全部分店', value: null })
			return ret
		},

		orderSummary() {
			let res = {
				dateCount: 0,
				pickupCount: 0,
				pickupTotal: 0,
				deliveryCount: 0,
				deliveryTotal: 0,
				count: 0,
				total: 0,
				days: [],
			}
			if (!this.startDate) return res

			let startDate = Number(moment(this.startDate).format('YYYYMMDD'))
			let endDate = Number(moment(this.endDate ? this.endDate : this.startDate).format('YYYYMMDD'))

			for (let currentDate = startDate; currentDate <= endDate; currentDate++) {
				if (moment(String(currentDate)).format('YYYY年MM月DD日') == 'Invalid date') continue
				let day = {
					date: moment(String(currentDate)).format('YYYY年MM月DD日'),
					pickupCount: 0,
					pickupTotal: 0,
					deliveryCount: 0,
					deliveryTotal: 0,
					count: 0,
					total: 0,
				}

				_.cloneDeep(this.orders).forEach((order) => {
					if (order.status != 'COMPLETED') return
					if (String(order.uniNum).includes(String(currentDate))) {
						if (this.shop && order.shop.id !== this.shop.id) return
						const startTime = moment(this.startTime, 'HH:mm:ss')
						const endTime = moment(this.endTime, 'HH:mm:ss')
						const completedAt = moment(
							moment
								.unix(Number(order.completedAt))
								.utcOffset(8)
								.format('HH:mm:ss'),
							'HH:mm:ss'
						)

						if (startTime && completedAt.isBefore(startTime)) return
						if (endTime && completedAt.isAfter(endTime)) return
						if (startTime && endTime && startTime.isAfter(endTime)) return

						if (order.method == 'PICKUP') {
							day.pickupCount += 1
							day.pickupTotal += order.grandTotal
						} else if (order.method == 'DELIVERY') {
							day.deliveryCount += 1
							day.deliveryTotal += order.grandTotal
						}
						day.count += 1
						day.total += order.grandTotal
					}
				})
				// } else {
				// 	_.cloneDeep(this.orders).forEach((order) => {
				// 		if (order.status != 'COMPLETED') return
				// 		if (String(order.uniNum).includes(String(currentDate))) {
				// 			if (order.method == 'PICKUP') {
				// 				day.pickupCount += 1
				// 				day.pickupTotal += order.grandTotal
				// 			} else if (order.method == 'DELIVERY') {
				// 				day.deliveryCount += 1
				// 				day.deliveryTotal += order.grandTotal
				// 			}
				// 			day.count += 1
				// 			day.total += order.grandTotal
				// 		}
				// 	})
				// }

				res.dateCount += 1
				res.pickupCount += day.pickupCount
				res.pickupTotal += day.pickupTotal
				res.deliveryCount += day.deliveryCount
				res.deliveryTotal += day.deliveryTotal
				res.count += day.count
				res.total += day.total
				res.days.push(day)
			}

			return res
		},
	},
}
</script>
